import "./SocialMedia.css";
import LogoFacebook from "../../../assets/img/icons8-facebook.svg";
import LogoInstagram from "../../../assets/img/icons8-instagram.svg";
import LogoLinkedIn from "../../../assets/img/icons8-linkedin.svg";
import LogoMail from "../../../assets/img/icons8-mail.svg";
import LogoWhatsApp from "../../../assets/img/icons8-whatsapp.svg";
import LogoYoutube from "../../../assets/img/icons8-youtube.svg";

function SocialMedia() {
  return (
    <section className="section-social-media">
      <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.linkedin.com/company/79832194/">
        <img
          src={LogoLinkedIn}
          alt="logo_linkedin">
        </img>
      </a>
      <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.instagram.com/vemobilemkt/">
        <img
          src={LogoInstagram}
          alt="logo_instagram"
        ></img>
      </a>
      <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.facebook.com/vemobile">
        <img
          src={LogoFacebook}
          alt="logo_facebook"
        ></img>
      </a>
      <a target="_blank" rel="nofollow noopener noreferrer" href="https://api.whatsapp.com/send?phone=5214421113874&text=Hola%20me%20interesa%20Vemobile">
        <img
          src={LogoWhatsApp}
          alt="logo_whatsapp"
        ></img>
      </a>
      <a target="_blank" rel="nofollow noopener noreferrer" href="mailto:atencion.clientes@timobile.com.mx">
        <img
          src={LogoMail}
          alt="logo_correo"
        ></img>
      </a>
      <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.youtube.com/@vemobilemx">
        <img
          src={LogoYoutube}
          alt="logo_youtube"
        ></img>
      </a>
    </section>
  );
}
export default SocialMedia;
