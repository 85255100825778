import "./Section6-HowWorksMovil.css";
import gastos_op from '../../../assets/img/gastos_op.webp'

import React from "react";

export const Section6HowWorksMovil = () => {
  return (
    <section className="section6-HowWorksMovil-container" id="Gastos">
      <section className="section6-HowWorksMovil-textimg">
        <section className="section6-HowWorksMovil">
          <h1 className="section6-HowWorksMovil-title">Gastos operativos</h1>
          <div className="section6-HowWorksMovil-text">
            <li className="section6-HowWorkMovil-li-subtitle">
            Lleva el control total de tus gastos en ruta:
            </li>
            <ul className="section6-HowWorkMovil-ul">
              <li className="section6-HowWorkMovil-li">
                Comida
              </li>
              <li className="section6-HowWorkMovil-li">
                Gasolina
              </li>
              <li className="section6-HowWorkMovil-li">
                Casetas
              </li>
              <li className="section6-HowWorkMovil-li">
                Imprevistos
              </li>
            </ul>
          </div>
        </section>
        <div className="section6-HowWorksMovil-img">
            <img className="section6-HowWorksMovil-img-gastos" src={gastos_op} alt="gastos" loading="lazy" />
        </div>
      </section>
    </section>
  );
};
