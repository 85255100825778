import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/Globals/Header/Header";
import Section1Testimony from "../components/Testimony/Section1/Section1-testimony"
import Section2Testimony from "../components/Testimony/Section2/Section2-testimony"
import WhatsApp from "../components/Globals/WhatsApp/WhatsApp";
import Messenger from "../components/Globals/Messenger/Messenger";

function Testimony(){
    const location = useLocation();
    const selectedPage = location.pathname; 
    const onPageSelectHandler = (page) => {
        // Lógica para manejar el cambio de página aquí
        console.log("Se seleccionó la página:", page);
      };
    return(
        <div>
            <Header onPageSelect={onPageSelectHandler} selectedPage={selectedPage} />
        <Section1Testimony/>
        <Section2Testimony/>
        <WhatsApp />
        <Messenger />
        </div>
    );
}
export default Testimony;