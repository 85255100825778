import React from "react";
import "./Section7-HowWorks.css";
import ventas from "../../../assets/img/ventas.webp";

export const Section7HowWorks = () => {
  return (
    <div>
      <div>
        <section className="section7-HowWorks-container" id="Ventas">
          <section className="section7-HowWorks-textimg">
            <section className="section7-HowWorks">
              <h1 className="section7-HowWorks-title">Ventas</h1>
              <div className="section7-HowWorks-text">
                <ul className="section7-HowWork-ul">
                  <li className="section7-HowWork-li">
                    Gestiona y levanta nuevas ventas desde tu plataforma web.
                  </li>
                  <li className="section7-HowWork-li">
                    Accede a reportes globales y gráficas en tiempo real de tus
                    ventas preventas y entregas y monitorea tu cobranza y
                    utilidades con el visor general.
                  </li>
                  <li className="section7-HowWork-li">
                    Visualiza a detalle la operación de tus vendedores en todo
                    momento.
                  </li>
                </ul>
              </div>
            </section>
            <div className="section7-HowWorks-img">
              <img
                className="section7-HowWorks-img-ventas"
                src={ventas}
                alt="ventas"
                loading="lazy"
              />
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};
