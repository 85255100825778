import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/Globals/Header/Header";
import Form from "../components/Contact/Form/Form"
import Form2 from "../components/Contact/Form/Form2"
import Messenger from "../components/Globals/Messenger/Messenger";
import WhatsApp from "../components/Globals/WhatsApp/WhatsApp";

function Contact() {
  const location = useLocation();
    const selectedPage = location.pathname; 
    const onPageSelectHandler = (page) => {
        // Lógica para manejar el cambio de página aquí
        console.log("Se seleccionó la página:", page);
      };
    return(
        <div>
            <Header onPageSelect={onPageSelectHandler} selectedPage={selectedPage} />
      <Form2/>
      <WhatsApp />
      <Messenger />
    </div>
  );
}
export default Contact;
