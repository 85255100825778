import React from 'react';
import PrivacyPoliciesComp from '../components/PrivacyPolicies/PrivacyPolicies';

const PrivacyPolicies = () => {
    return (
        <div>
            <PrivacyPoliciesComp />
        </div>
    );
}

export default PrivacyPolicies;
