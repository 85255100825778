import { apiMessenger } from '../../../routes/PathRoutes';
import MessengerIcon from '../../../assets/img/messenger.png';
import './Messenger.css';

function Messenger() {
  return (
    <span>
      <a target='_blank' className='messenger-icon-link' href={apiMessenger.link}>
        <img src={MessengerIcon} alt='messenger-icon' className='messenger-icon' />
      </a>
    </span>
  );
}

export default Messenger;