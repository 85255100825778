import './Section4-HowWorks.css'
import clientes from '../../../assets/img/clientes.webp'

import React from 'react'

export const Section4HowWorks = () => {
  return (
    <div>    <section className="section4-HowWorks-container" id='Clientes'>
    <section className="section4-HowWorks-textimg">
      <section className="section4-HowWorks">
        <h1 className="section4-HowWorks-title">Clientes</h1>
          <div className="section4-HowWorks-text">
            <ul className="section4-HowWork-ul">
              <li className="section4-HowWork-li">
                Consulta, agrega, edita o elimina clientes de tus listas. 
              </li>
              <li className="section4-HowWork-li">
                Determina los tipos de venta (crédito y/o contado) por cliente y por vendedor, así como asignarles listas de precios especiales.               </li>
            </ul>
          </div>
      </section>
      <div className="section4-HowWorks-img">
        <img className="section4-HowWorks-img-clientes" src={clientes} alt="clientes" loading="lazy"/>
      </div>
    </section>
  </section></div>
  )
}
