import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import HowWorks from "../pages/HowWorks";
import PrivacyPolicies from "../pages/PrivacyPolicies";
import Testimony from "../pages/Testimony";
import { pathRoutes } from "./PathRoutes";
import HowWorksMovil from "../pages/HowWorksMovil";
import FrecuentQuestions from "../components/FAQs/FAQs";


function WebRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={pathRoutes.home} Component={Home}></Route>
        <Route path={pathRoutes.howWorks} Component={HowWorks}></Route>
        <Route path={pathRoutes.testimony} Component={Testimony}></Route>
        <Route path={pathRoutes.contact} Component={Contact}></Route>
        <Route path={pathRoutes.privacyPolicies} Component={PrivacyPolicies}></Route>
        <Route path={pathRoutes.frecuentQuestions} Component={FrecuentQuestions}></Route>
        <Route path={pathRoutes.howWorksMovil} Component={HowWorksMovil}></Route>
      </Routes>
    </BrowserRouter>
  );
}
export default WebRoutes;
