import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/Globals/Header/Header";
import BannerYoutube from "../components/LearnMore/BannerYoutube";
import {Section1HowWorks} from "../components/HowWorks/Section1/Section1-HowWorks"
import {Section2HowWorks} from "../components/HowWorks/Section2/Section2-HowWorks"
import {Section3HowWorks} from "../components/HowWorks/Section3/Section3-HowWorks"
import {Section4HowWorks} from "../components/HowWorks/Section4/Section4-HowWorks"
import {Section5HowWorks} from "../components/HowWorks/Section5/Section5-HowWorks"
import {Section6HowWorks} from "../components/HowWorks/Section6/Section6-HowWorks"
import {Section7HowWorks} from "../components/HowWorks/Section7/Section7-HowWorks"
import {Section8HowWorks} from "../components/HowWorks/Section8/Section8-HowWorks"
import WhatsApp from "../components/Globals/WhatsApp/WhatsApp";
import Messenger from "../components/Globals/Messenger/Messenger";

function LearnMore(){
    const location = useLocation();
    const selectedPage = location.pathname; 
    const onPageSelectHandler = (page) => {
        // Lógica para manejar el cambio de página aquí
        console.log("Se seleccionó la página:", page);
    };

   
    return(
        <div>
            <Header onPageSelect={onPageSelectHandler} selectedPage={selectedPage} />
            <Section1HowWorks/>
            <Section2HowWorks/>
            <Section3HowWorks/>
            <Section4HowWorks/>
            <Section5HowWorks/>
            <Section6HowWorks/>
            <Section7HowWorks/>
            <Section8HowWorks/>
            <WhatsApp />
            <Messenger />
            <BannerYoutube/>
        </div>
    );
}
export default LearnMore;