import thuna_hielo from "../../../assets/img/thuna_hielo.webp";
import forman_hielo from "../../../assets/img/forman_hielo.webp";
import iglu_hielo from "../../../assets/img/iglu_hielo.webp";
import innova_ice_hielo from "../../../assets/img/innova_ice_hielo.webp";
import majestad_abarrotes from "../../../assets/img/majestad_abarrotes.webp";
import fryen_abarrotes from "../../../assets/img/fryen_abarrotes.webp";
import bonlatti_abarrotes from "../../../assets/img/bonlatti_abarrotes.webp";
import coronel_lácteos from "../../../assets/img/coronel_lácteos.webp";
import vitere_lacteo from "../../../assets/img/vitere_lacteo.webp";
import dunedin_materia_prima from "../../../assets/img/dunedin_materia_prima.webp";
import Mexicana_de_Arroz_materia_prima from "../../../assets/img/Mexicana_de_Arroz_materia_prima.webp";
import "./Section1-testimony.css";

function Section1Testimony() {
  return (
    <section className="section1-testimony-body">
      <section className="section1-testimony">
        <p className="section1-testimony-text section1-testimony-text1">
          Estamos comprometidos 100% con nuestros clientes.
        </p>
        <p className="section1-testimony-text section1-testimony-text2">
          Te presentamos algunos de nuestros clientes:
        </p>

        <article className="section1-testimony-clients section1-testimony-clients-hieleros">
          <p className="section1-testimony-subtitle">Hieleros</p>
          <img
            className="section1-img section1-img-1"
            src={thuna_hielo}
            alt="thunahielo"
          />
          <img className="section1-img" src={forman_hielo} alt="forman_hielo" />
          <img className="section1-img" src={iglu_hielo} alt="iglu_hielo" />
          <img
            className="section1-img"
            src={innova_ice_hielo}
            alt="innova_ice_hielo"
          />
        </article>
        <article className="section1-testimony-clients section1-testimony-clients-abarrotes">
          <p className="section1-testimony-subtitle">Abarrotes</p>
          <img
            className="section1-img section1-img-1"
            src={majestad_abarrotes}
            alt="majestad_abarrotes"
          />
          <img
            className="section1-img"
            src={fryen_abarrotes}
            alt="fryen_abarrotes"
          />
          <img
            className="section1-img"
            src={bonlatti_abarrotes}
            alt="bonlatti_abarrotes"
          />
        </article>
        <article className="section1-testimony-clients section1-testimony-clients-lacteos">
          <p className="section1-testimony-subtitle">Lácteos</p>
          <img
            className="section1-img section1-img-1"
            src={coronel_lácteos}
            alt="coronel_lácteos"
          />
          <img
            className="section1-img"
            src={vitere_lacteo}
            alt="vitere_lacteo"
          />
        </article>
        <article className="section1-testimony-clients section1-testimony-clients-materia">
          <p className="section1-testimony-subtitle">Materia Prima</p>
          <img
            className="section1-img section1-img-1"
            src={dunedin_materia_prima}
            alt="dunedin_materia_prima"
          />
          <img
            className="section1-img"
            src={Mexicana_de_Arroz_materia_prima}
            alt="Mexicana_de_Arroz_materia_prima"
          />
        </article>
      </section>
    </section>
  );
}
export default Section1Testimony;
