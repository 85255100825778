import React from "react";
import "./Section1-HowWorks.css";
import isotipo from "../../../assets/img/isotipo.webp";
import { useNavigate } from 'react-router-dom'; 
import { useState } from 'react';

export const Section1HowWorks = () => {
  const navigate = useNavigate();

  const handleClick1 = () =>{
    navigate('/comofuncionamovil')
  }

  const handleClick2 = () =>{
    navigate('/comofunciona')
  }

  const [isOpen, setIsOpen] = useState(false);
    
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  
  const handleEvent1 = () =>{
    handleClick2();
    toggleDropdown();
  }


  return (
    <section className="section1-HowWorks-container">
      <section className="section1-HowWorks">
        <h1 className="section1-HowWorks-title">
          Aprende todo sobre la herramienta VeMobile!
        </h1>
        <div className="section1-HowWorks-text">
          <div className="section1-HowWorks-text-image">
            <div className="section1-HowWorks-text-container">
              <h3 className="section1-HowWorks-text1">
                Gestiona efectivamente las rutas de tus vendedores ya que te
                permite:
              </h3>
              <ul className="section1-HowWorks-text-ul">
                <li className="section1-HowWorks-text-li">
                  Definir y gestionar las metas de cada uno de tus vendedores y
                  tu equipo completo.
                </li>
                <li className="section1-HowWorks-text-li">
                  Examinar tus rutas en tiempo real.
                </li>
                <li className="section1-HowWorks-text-li">
                  Revisar el cumplimiento de agendas.
                </li>
                <li className="section1-HowWorks-text-li">
                  Controlar la efectividad de las visitas a Clientes.
                </li>
                <li className="section1-HowWorks-text-li">
                  Minimizar las malas prácticas en ruta.
                </li>
                <li className="section1-HowWorks-text-li">
                  Obtener reportes personalizados.
                </li>
                <li className="section1-HowWorks-text-li">
                  Conecta la interfaz a tu sistema administrativo.
                </li>
              </ul>
            </div>
            <img className="section1-HowWorks-img" src={isotipo} alt="isotipo" loading="lazy"/>
          </div>
          <div className="section1-HowWorks-buttons">
          <button className="section1-HowWorks-button1" type="submit" onClick={handleClick1} >App Móvil</button>
          <div className="dropdown-container">
          <button className="section1-HowWorks-button2" type="submit" onClick={handleEvent1}>Plataforma Web</button>
          {isOpen && (
        <ul className="dropdown-list">
          <li><a className="button-list" href="#Configuración">Configuración</a></li>
          <li><a className="button-list" href="#Catálogos">Catálogos</a></li>
          <li><a className="button-list" href="#Clientes">Clientes</a></li>
          <li><a className="button-list" href="#Productos">Productos</a></li>
          <li><a className="button-list" href="#Rutas">Rutas</a></li>
          <li><a className="button-list" href="#Ventas">Ventas</a></li>
          <li><a className="button-list" href="#Inventario">Inventario</a></li>
        </ul>
      )}
          </div>
          </div>
        </div>
      </section>
    </section>
  );
};
