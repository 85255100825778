import React from 'react';
import Banner1 from '../../../assets/img/imagen_seccion.png';
import './Section1.css';

function Section1() {
  return (
    <section className='section1'>
      <article className='section1-info'>
          
          <button>Pide tu demo</button>
      
      </article>

      
    </section>
  );
}

export default Section1;
