import React from "react";
import rutas from "../../../assets/img/rutas.webp";
import "./Section6-HowWorks.css";

export const Section6HowWorks = () => {
  return (
    <div>
      <section className="section6-HowWorks-container" id="Rutas">
        <section className="section6-HowWorks-textimg">
          <section className="section6-HowWorks">
            <h1 className="section6-HowWorks-title">Rutas</h1>
            <div className="section6-HowWorks-text">
              <ul className="section6-HowWork-ul">
                <li className="section6-HowWork-li">
                  Administra y gestiona tus rutas de venta en tiempo real.
                </li>
                <li className="section6-HowWork-li">
                  Define la agenda diaria de tus vendedores, considera los
                  gastos operativos de la ruta/vendedor, etc.
                </li>
                <li className="section6-HowWork-li">
                  Calcula tus rutas de preventa y entrega.
                </li>
              </ul>
            </div>
          </section>
          <div className="section6-HowWorks-img">
            <img
              className="section6-HowWorks-img-rutas"
              src={rutas}
              alt="rutas"
              loading="lazy"
            />
          </div>
        </section>
      </section>
    </div>
  );
};
