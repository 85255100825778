import "./Section3-HowWorks.css";
import catalogos from '../../../assets/img/catalogos.webp'

import React from "react";

export const Section3HowWorks = () => {
  return (
    <section className="section3-HowWorks-container" id="Catálogos">
      <section className="section3-HowWorks-textimg">
        <section className="section3-HowWorks">
          <h1 className="section3-HowWorks-title">Catálogos</h1>
          <div className="section3-HowWorks-text">
            <li className="section3-HowWork-li-subtitle">
            Personaliza tus catálogos de acuerdo a tu proceso personalizado de ventas:  
            </li>
            <ul className="section3-HowWork-ul">
              <li className="section3-HowWork-li">
                Causas de no venta
              </li>
              <li className="section3-HowWork-li">
                Rubros de gastos operativos
              </li>
              <li className="section3-HowWork-li">
                Listas de precios y precios por zona
              </li>
              <li className="section3-HowWork-li">
                Unidades de medida
              </li>
              <li className="section3-HowWork-li">
                Causas de no entrega (aplica para preventas y entregas)
              </li>
            </ul>
          </div>
        </section>
        <div className="section2-HowWorks-img">
            <img className="section3-HowWorks-img-catalogos" src={catalogos} alt="catalogos" loading="lazy"/>
        </div>
      </section>
    </section>
  );
};
