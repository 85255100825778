import React from "react";
import "./Section2-HowWorksMovil.css";
import clientesMovil from "../../../assets/img/clientesMovil.webp";

export const Section2HowWorksMovil = () => {
  return (
    <section className="section2-HowWorksMovil-container" id="Clientes">
      <section className="section2-HowWorksMovil-textimg">
        <section className="section2-HowWorksMovil">
          <h1 className="section2-HowWorksMovil-title">Clientes</h1>
            <div className="section2-HowWorksMovil-text">
              <ul className="section2-HowWorkMovil-ul">
                <li className="section2-HowWorkMovil-li">
                Visualiza los Clientes asignados a tu ruta, el tipo de operación que debes realizar, ubicación, saldos, límite de crédito, entre otros. 
                </li>
                <li className="section2-HowWorkMovil-li">
                Realiza operaciones de venta de contado, crédito, cobranza, preventa, entrega y no venta.
                </li>
                <li className="section2-HowWorkMovil-li">
                Imprime o envía tus tickets por Whatsapp a tus Clientes.
                </li>
              </ul>
            </div>
        </section>
        <div className="section2-HowWorksMovil-img">
          <img
            className="section2-HowWorksMovil-img-clientes"
            src={clientesMovil}
            alt="configuracion"
            loading="lazy"
          />
        </div>
      </section>
    </section>
  );
};
