import { Link } from "react-router-dom";
import Logo from "../../../assets/img/logo.webp";
import LogoFacebook from "../../../assets/img/icons8-facebook.svg";
import LogoInstagram from "../../../assets/img/icons8-instagram.svg";
import LogoLinkedIn from "../../../assets/img/icons8-linkedin.svg";
import LogoMail from "../../../assets/img/icons8-mail.svg";
import LogoWhatsApp from "../../../assets/img/icons8-whatsapp.svg";
import LogoYoutube from "../../../assets/img/icons8-youtube.svg";

import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <section className="footer-top">
        <article className="footer-logo">
          <img src={Logo} alt="logo" className="footer-logo-img" />
        </article>
        <article className="footer-sections">
          <section className="footer-section-row">
            <aside>
              <ul className="footer-sections-list-items">
                <li className="footer-sections-list fsl-title">
                  ¿Cómo funciona?
                </li>
                <li className="footer-sections-list">
    <Link to='/comofunciona'>Plataforma Web</Link> {/* Enlaza a la página correspondiente */}
  </li>
                <li className="footer-sections-list"><Link to='/comofunciona'>App Movil</Link></li>
                <li><Link to='/FAQs'>FAQs</Link></li>
              </ul>
            </aside>

            <aside>
              <ul className="footer-sections-list-items">
                <li className="footer-sections-list fsl-title">Casos de éxito</li>
                <li><Link to='/testimonios'>Clientes</Link></li>
                <li><Link to='/testimonios'>Testimonios</Link></li>
              </ul>
            </aside>
          </section>
          <section className="footer-section-row">
            <aside>
              <ul className="footer-sections-list-items">
                <li className="footer-sections-list fsl-title">Contáctanos</li>
                <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.facebook.com/vemobile">
                  <img
                    src={LogoWhatsApp}
                    alt="logo_whatsapp"
                  ></img></a>
                <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.facebook.com/vemobile">
                  <img
                    src={LogoMail}
                    alt="logo_mail"
                  ></img></a>
              </ul>
            </aside>

            <Link to='/contact' className="footer-sections-btn"> DEMO GRATIS </Link>
          </section>
        </article>
      </section>

      <section className="footer-divider"><hr></hr></section>

      <section className="footer-bottom">
        <article className="footer-bottom-info">
          <p>Contacto</p>
          <p>442 359 1568 / 442 119 9085</p>
          <p>atencion.clientes@timobile.com.mx</p>
          <p>ventas@timobile.com.mx</p>
        </article>
        <article className="footer-bottom-social-media">
          <p>Síguenos:</p>
          <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.facebook.com/vemobile">
            <img
              src={LogoFacebook}
              alt="logo_facebook"
            ></img>
          </a>
          <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.linkedin.com/company/79832194/">
            <img
              src={LogoLinkedIn}
              alt="logo_linkedin">
            </img>
          </a>
          <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.instagram.com/vemobilemkt/">
            <img
              src={LogoInstagram}
              alt="logo_instagram"
            ></img>
          </a>
          <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.youtube.com/@vemobilemx">
            <img
              src={LogoYoutube}
              alt="logo_youtube"
            ></img>
          </a>

        </article>
        <article className="footer"></article>
      </section>

      <section className="footer-privacy">
        <p>
          © VeMobile 2023.
          <span> | </span>
          <Link className="footer-privacy-link" to="/privacypolicies">
            Aviso de privacidad
          </Link>
          <span> | </span>
          <Link className="footer-faqs-link" to="/FAQs">
            FAQS
          </Link>
        </p>
      </section>
    </footer>
  );
}
export default Footer;
