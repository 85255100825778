import React from "react";
import cierre_del_dia from "../../../assets/img/cierre_del_dia.webp";
import "./Section5-HowWorksMovil.css";

export const Section5HowWorksMovil = () => {
  return (
    <div>
      <section className="section5-HowWorksMovil-container" id="Cierre">
        <section className="section5-HowWorksMovil-textimg">
          <section className="section5-HowWorksMovil">
            <h1 className="section5-HowWorksMovil-title">Cierre del día</h1>
            <div className="section5-HowWorksMovil-text">
              <ul className="section5-HowWorkMovil-ul">
                <li className="section5-HowWorkMovil-li">
                Sincroniza y cierra tu día de manera ágil y sencilla, aquí podrás visualizar el detalle de todas las operaciones de tu ruta, así como la liquidación correspondiente. ¡El cierre de tu día en pocos clicks! 
                </li>
              </ul>
            </div>
          </section>
          <div className="section5-HowWorksMovil-img">
            <img
              className="section5-HowWorksMovil-img-cierre"
              src={cierre_del_dia}
              alt="cierre"
              loading="lazy"
            />
          </div>
        </section>
      </section>
    </div>
  );
};
