import React from "react";
import "./Section2.css";
import dummy1 from "../../../assets/img/dummy1.png";
import dummy2 from "../../../assets/img/dummy2.png";
import dummy3 from "../../../assets/img/dummy3.png";
import dummy4 from "../../../assets/img/dummy4.png";
import dummy5 from "../../../assets/img/dummy5.png";
import dummy6 from "../../../assets/img/dummy6.png";
import dummy7 from "../../../assets/img/dummy7.png";
import dummy8 from "../../../assets/img/dummy8.png";
import dummy9 from "../../../assets/img/dummy9.png";
import dummy10 from "../../../assets/img/dummy10.png";
import dummy11 from "../../../assets/img/dummy11.png";
import isotipo from "../../../assets/img/isotipo.webp";

const Section2 = () => {
  return (
    <section className="section2">
      <div className="container-text">
        <article className="section2-text">
          <p className="text-1">
            VeMobile es <b>tu aliado</b> para gestionar tranquilamente y sin
            problemas tu operación comercial en ruta
          </p>
          <p className="text-2">
            <b>Optimiza y gestiona</b>, desde tu celular y de manera
            personalizada, cada etapa del proceso de ventas en ruta desde tu
            celular:
          </p>
        </article>
        <img className="img-isotipo" src={isotipo} alt="isotipo" />
      </div>

      <article className="section2-info">
        <ul className="ul-1">
          <li>
            <img className="img-dummy" src={dummy1} alt="dummy1" />
            <span>Cartera de Clientes</span>
          </li>
          <li>
            <img className="img-dummy color" src={dummy2} alt="dummy2" />
            <span>Rutas de ventas </span>
          </li>
          <li>
            <img className="img-dummy" src={dummy3} alt="dummy3" />
            <span>Levantamiento de pedidos y ventas</span>
          </li>
          <li>
            <img className="img-dummy color" src={dummy4} alt="dummy4" />
            <span>Impresión de Tickets en el momento</span>
          </li>
          <li>
            <img className="img-dummy" src={dummy5} alt="dummy5" />
            <span>Creación de listas de precios personalizadas</span>
          </li>
          <li>
            <img className="img-dummy color" src={dummy6} alt="dummy6" />
            <span>Promociones y Descuentos</span>
          </li>
        </ul>
        <ul className="ul-2">
          <li>
            <img className="img-dummy" src={dummy7} alt="dummy7" />
            <span>Inventarios</span>
          </li>
          <li>
            <img className="img-dummy color" src={dummy8} alt="dummy8" />
            <span>Entregas y Cobranzas</span>
          </li>
          <li>
            <img className="img-dummy" src={dummy9} alt="dummy9" />
            <span>Administra la agenda de todos</span>
          </li>
          <li>
            <img className="img-dummy color" src={dummy10} alt="dummy10" />
            <span>Cierre del día</span>
          </li>
          <li>
            <img className="img-dummy" src={dummy11} alt="dummy11" />
            <span>Gestión de gastos operativos, entre otros</span>
          </li>
        </ul>
      </article>
    </section>
  );
};

export default Section2;
