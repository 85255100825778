import React from "react";
import Footer from "../components/Globals/Footer/Footer";
import Section3 from "../components/Home/Section3/Section3";
import Header from "../components/Globals/Header/Header";
import Messenger from "../components/Globals/Messenger/Messenger";
import WhatsApp from "../components/Globals/WhatsApp/WhatsApp";
import Section2 from "../components/Home/Section2/Section2";
import '../../src/components/Globals/WhatsApp/WhatsApp.css';
import Section1 from "../components/Home/Section1/Section1";
import Section4 from "../components/Home/Section4/Section4";
import { useLocation } from "react-router-dom";
/**
 *
 * @returns {React.Component} pagina Home
 */

function Home() {
  const location = useLocation();
    const selectedPage = location.pathname; 
    const onPageSelectHandler = (page) => {
        // Lógica para manejar el cambio de página aquí
        console.log("Se seleccionó la página:", page);
      };
    return(
        <div>
            <Header onPageSelect={onPageSelectHandler} selectedPage={selectedPage} />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <WhatsApp />
      <Messenger />
      <Footer />
    </div>
  );
}
export default Home;
