import React from "react";
import "./Section4.css";
import Logo from '../../../assets/img/logo.webp';
import Form from "../../Contact/Form/Form";
import SocialMedia from "../../Globals/SocialMedia/SocialMedia";

function Section4() {
  return (
    <section className="section4">
      <div className="section4-column">
        <article className="section4-info">
          <p>
            Contáctanos para conocer tus necesidades
            y ofrecerte una solución personalizada y
            efectiva que te optimice tus tiempos para
            generar más ventas.
          </p>
          <div className="section4-info-spacer" />
          <SocialMedia />
        </article>

      </div>
      <article className="section4-form">
        <Form />
        </article>
      </section>
  );
}

export default Section4;
