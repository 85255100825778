import React from "react";
import "./Section7-HowWorksMovil.css";
import kilometraje from "../../../assets/img/kilometraje.webp";

export const Section7HowWorksMovil = () => {
  return (
    <div>
      <div>
        <section className="section7-HowWorksMovil-container" id="Kilometraje">
          <section className="section7-HowWorksMovil-textimg">
            <section className="section7-HowWorksMovil">
              <h1 className="section7-HowWorksMovil-title">Kilometraje</h1>
              <div className="section7-HowWorksMovil-text">
                <ul className="section7-HowWorkMovil-ul">
                  <li className="section7-HowWorkMovil-li">
                  Registra tu kilometraje al iniciar y terminar tu jornada para llevar un mejor control de tu unidad de reparto y tus trayectos. 
                  </li>
                </ul>
              </div>
            </section>
            <div className="section7-HowWorksMovil-img">
              <img
                className="section7-HowWorksMovil-img-kilometraje"
                src={kilometraje}
                alt="kilometraje"
                loading="lazy"
              />
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};
