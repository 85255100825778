import React from 'react'
import sincronizar from '../../../assets/img/sincronizar.webp'
import './Section8-HowWorksMovil.css'

export const Section8HowWorksMovil = () => {
  return (
    <section className="section8-HowWorksMovil-container" id="Sincronizar">
      <section className="section8-HowWorksMovil-textimg">
        <section className="section8-HowWorksMovil">
          <h1 className="section8-HowWorksMovil-title">Sincronizar</h1>
            <div className="section8-HowWorksMovil-text">
              <ul className="section8-HowWorkMovil-ul">
                <li className="section8-HowWorkMovil-li">
                Sincroniza tus catálogos de manera oportuna para que siempre tengas actualizada toda la información de tu ruta (Clientes, productos, políticas, listas, promociones, etc.)
                </li>
              </ul>
            </div>
        </section>
        <div className="section8-HowWorksMovil-img">
          <img
            className="section8-HowWorksMovil-img-syncr"
            src={sincronizar}
            alt="syncr"
            loading="lazy"
          />
        </div>
      </section>
    </section>
  )
}
