import "./BannerYoutube.css";
import Banner from "../../../src/assets/img/iconoComoFunciona.svg";
import YouTubeIcon from '@mui/icons-material/YouTube';

function BannerYoutube() {
  return (
    <section className="section-banner">
        <section className="banner-img">        
        <a href="https://www.youtube.com/@vemobilemx">
          <YouTubeIcon fontSize="large" className='youtube-icon'/>
        </a>
        </section>
    </section>
  );
}
export default BannerYoutube;
