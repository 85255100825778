import React from "react";
import Logo from "../../assets/img/logo.webp";
import "./FAQs.css";

const FrecuentQuestions = () => {
  // Define un arreglo de objetos con las preguntas y respuestas
  const faqs = [
    {
      pregunta: "¿Qué tipos de bienes transportan?",
      respuesta: "Realizamos el transporte de una amplia variedad de bienes, incluyendo productos manufacturados, materias primas, productos perecederos, carga a granel y mucho más.",
    },
    {
      pregunta: "¿Cuál es el alcance de su área de servicio?",
      respuesta: "Ofrecemos servicios de transporte a nivel nacional/internacional, cubriendo rutas en diversas regiones. Nuestra red de distribución nos permite llegar a numerosos destinos.",
    },
    {
      pregunta: "¿Cómo garantizan la seguridad de mis bienes durante el transporte?",
      respuesta: "La seguridad es nuestra prioridad. Utilizamos tecnología avanzada de seguimiento y monitoreo, conductores capacitados, y vehículos que cumplen con los estándares de seguridad. Además, ofrecemos seguros para proteger los bienes en tránsito.",
    },
    {
      pregunta: "¿Cuál es su enfoque en la puntualidad de las entregas?",
      respuesta: "Nos comprometemos a cumplir con los plazos de entrega acordados. Utilizamos rutas eficientes, planificación avanzada y sistemas de seguimiento en tiempo real para garantizar que sus bienes lleguen a tiempo.",
    },
    {
      pregunta: "¿Cómo manejan situaciones de retraso o problemas en la entrega?",
      respuesta: "En caso de retrasos o problemas, mantenemos una comunicación transparente con nuestros clientes y trabajamos para resolver la situación de manera efectiva. Nuestro equipo está disponible para brindar actualizaciones y soluciones alternativas.",
    },
    {
      pregunta: "¿Ofrecen servicios de transporte especializados?",
      respuesta: "Sí, ofrecemos servicios especializados como transporte refrigerado, transporte de carga sobredimensionada y transporte de productos peligrosos. Contamos con el equipo y la experiencia para manejar diversos tipos de envíos.",
    },
    {
      pregunta: "¿Cómo puedo hacer un seguimiento de mis envíos?",
      respuesta: "Proporcionamos acceso a plataformas en línea donde puedes realizar un seguimiento en tiempo real de tus envíos. También puedes comunicarte con nuestro equipo de atención al cliente para obtener actualizaciones.",
    },
    {
      pregunta: "¿Cuáles son sus tarifas de transporte?",
      respuesta: "Nuestras tarifas varían según factores como la distancia, el tipo de carga, el modo de transporte y otros detalles. Te invitamos a contactarnos para obtener una cotización personalizada y competitiva.",
    },
    {
      pregunta: "¿Ofrecen servicios de consolidación de carga?",
      respuesta: "Sí, ofrecemos servicios de consolidación de carga para ayudarte a maximizar la eficiencia y reducir costos al combinar múltiples envíos en un solo transporte.",
    },
    {
      pregunta: "¿Cómo puedo solicitar sus servicios de transporte?",
      respuesta: "Puedes comunicarte con nuestro equipo a través de [información de contacto]. Estaremos encantados de discutir tus necesidades y proporcionarte una solución de transporte adaptada a tus requerimientos.",
    },
  ];

  return (
    <div className="faq-container">
      <div className="privacy-logo">
        <p className="c9">
          <span className="privacy-span">
            <img alt="logo" src={Logo} className="privacy-logo-img" title="logo" />
          </span>
        </p>
      </div>
      <p className="c6">
        <span className="c2">PREGUNTAS FRECUENTES</span>
      </p>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <h3 className="faq-question">{faq.pregunta}</h3>
            <p className="faq-answer">{faq.respuesta}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FrecuentQuestions;
