import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
import thuna_hielo from "../../../assets/img/thuna_hielo.webp";
import majestad_abarrotes from "../../../assets/img/majestad_abarrotes.webp";
import coronel_lácteos from "../../../assets/img/coronel_lácteos.webp";
import dunedin_materia_prima from "../../../assets/img/dunedin_materia_prima.webp";
import Mexicana_de_Arroz_materia_prima from "../../../assets/img/Mexicana_de_Arroz_materia_prima.webp";
import "./Section2-testimony.css";

const testimonies = [
  "¡Increíble servicio de entregas! Siempre llegan puntualmente y en perfecto estado. Confiamos en ellos para entregar nuestros productos frescos a tiempo.",
  "Rápidos y confiables. Nuestros productos electrónicos siempre llegan seguros a su destino, gracias a su manejo cuidadoso. ¡Altamente recomendados!",
  "La precisión en la entrega de productos médicos es crucial para nosotros. Estamos impresionados con la meticulosidad de sus entregas, asegurando la salud de nuestros pacientes.",
  "Manejan nuestras exportaciones textiles con profesionalismo. Cada prenda llega doblada y lista para ser exhibida. Su atención al detalle es lo que nos hace elegirlos una y otra vez.",
  "Como proveedores de piezas automotrices, necesitamos entregas rápidas para mantener la producción. Estos chicos cumplen, incluso en los momentos más apremiantes. ¡Un socio logístico excepcional!"
];
const images = [
  thuna_hielo,
  majestad_abarrotes,
  coronel_lácteos,
  dunedin_materia_prima,
  Mexicana_de_Arroz_materia_prima
];

function Section2Testimony() {
  return (
    <section className="section2-testimony-body">
      <section className="section2-testimony">
        <p className="section2-testimony-text">¡Estás en buenas manos!</p>
        <section className="carousel">
          <Carousel 
          showArrows={true} 
          showThumbs={false} 
          showStatus={false} 
          infiniteLoop={true}  >
            {testimonies.map((testimony, index) => (
              <div key={index} className="carousel-testimony">
                <div className="testimony-content">
                  <p className="testimony-text">{testimony}</p>
                </div>
                <div className="testimony-image">
                  <img src={images[index]} alt={`Testimony ${index}`} />
                </div>
              </div>
            ))}
          </Carousel>
        </section>
      </section>
    </section>
  );
}

export default Section2Testimony;
