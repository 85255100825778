import React from "react";
import "./Section1-HowWorksMovil.css";
import isotipo from "../../../assets/img/isotipo.webp";
import { useNavigate } from "react-router-dom";
import {useState} from 'react'

export const Section1HowWorksMovil = () => {
  const navigate = useNavigate();
  const handleClick1 = () =>{
    navigate('/comofuncionamovil')
  }

  const handleClick2 = () =>{
    navigate('/comofunciona')
  }

  const [isOpen, setIsOpen] = useState(false);
    
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  
  const handleEvent1 = () =>{
    handleClick1();
    toggleDropdown();
  }


  return (
    <section className="section1-HowWorksMovil-container">
      <section className="section1-HowWorksMovil">
        <h1 className="section1-HowWorksMovil-title">
          Aprende todo sobre la herramienta VeMobile!
        </h1>
        <div className="section1-HowWorksMovil-text">
          <div className="section1-HowWorksMovil-text-image">
            <div className="section1-HowWorksMovil-text-container">
              <h3 className="section1-HowWorksMovil-text1">
                Gestiona efectivamente las rutas de tus vendedores ya que te
                permite:
              </h3>
              <ul className="section1-HowWorksMovil-text-ul">
                <li className="section1-HowWorksMovil-text-li">
                  Definir y gestionar las metas de cada uno de tus vendedores y
                  tu equipo completo.
                </li>
                <li className="section1-HowWorksMovil-text-li">
                  Examinar tus rutas en tiempo real.
                </li>
                <li className="section1-HowWorksMovil-text-li">
                  Revisar el cumplimiento de agendas.
                </li>
                <li className="section1-HowWorksMovil-text-li">
                  Controlar la efectividad de las visitas a Clientes.
                </li>
                <li className="section1-HowWorksMovil-text-li">
                  Minimizar las malas prácticas en ruta.
                </li>
                <li className="section1-HowWorksMovil-text-li">
                  Obtener reportes personalizados.
                </li>
                <li className="section1-HowWorksMovil-text-li">
                  Conecta la interfaz a tu sistema administrativo.
                </li>
              </ul>
            </div>
            <img className="section1-HowWorksMovil-img" src={isotipo} alt="" loading="lazy"/>
          </div>
          <div className="section1-HowWorksMovil-buttons">
          <div className="dropdown2-container">

          <button className="section1-HowWorksMovil-button1" type="submit" onClick={handleEvent1}>App Móvil</button>
          {isOpen && (
        <ul className="dropdown2-list">
          <li><a className="button2-list" href="#Clientes">Clientes</a></li>
          <li><a className="button2-list" href="#Productos">Productos</a></li>
          <li><a className="button2-list" href="#Reportes">Reportes</a></li>
          <li><a className="button2-list" href="#Cierre">Cierre del día</a></li>
          <li><a className="button2-list" href="#Gastos">Gastos operativos</a></li>
          <li><a className="button2-list" href="#Kilometraje">Kilometraje</a></li>
          <li><a className="button2-list" href="#Sincronizar">Sincronizar</a></li>
        </ul>
      )}
        </div>
          <button className="section1-HowWorksMovil-button2" type="submit" onClick={handleClick2}>Plataforma Web</button>
          </div>
        </div>
      </section>
    </section>
  );
};
