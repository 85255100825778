import React from 'react'
import inventario from '../../../assets/img/inventario.webp'
import './Section8-HowWorks.css'

export const Section8HowWorks = () => {
  return (
    <section className="section8-HowWorks-container" id='Inventario'>
      <section className="section8-HowWorks-textimg">
        <section className="section8-HowWorks">
          <h1 className="section8-HowWorks-title">Inventario</h1>
            <div className="section8-HowWorks-text">
              <ul className="section8-HowWork-ul">
                <li className="section8-HowWork-li">
                Gestiona tus inventarios desde tu plataforma web, carga inventario móvil a tu vendedor en ruta y controla tus devoluciones y mermas. 
                </li>
              </ul>
            </div>
        </section>
        <div className="section8-HowWorks-img">
          <img
            className="section8-HowWorks-img-inventario"
            src={inventario}
            alt="configuracion"
            loading="lazy"
          />
        </div>
      </section>
    </section>
  )
}
