import React from "react";
import productos from "../../../assets/img/productos.webp";
import "./Section5-HowWorks.css";

export const Section5HowWorks = () => {
  return (
    <div>
      <section className="section5-HowWorks-container" id="Productos">
        <section className="section5-HowWorks-textimg">
          <section className="section5-HowWorks">
            <h1 className="section5-HowWorks-title">Productos</h1>
            <div className="section5-HowWorks-text">
              <ul className="section5-HowWork-ul">
                <li className="section5-HowWork-li">
                  Agrega, modifica y consulta todos tus productos existentes,
                  descuentos, promociones, etc.
                </li>
              </ul>
            </div>
          </section>
          <div className="section5-HowWorks-img">
            <img
              className="section5-HowWorks-img-productos"
              src={productos}
              alt="productos "
              loading="lazy"
            />
          </div>
        </section>
      </section>
    </div>
  );
};
