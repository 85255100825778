import React from "react";
import Logo from "../../assets/img/logo.webp";
import "./Privacy.css";

const PrivacyPolicies = () => {

  return (
    <div className="privacy">
      <div className="privacy-logo">
        <p class="c9">
          <span className="privacy-span">
            <img alt="logo" src={Logo} className="privacy-logo-img" title="logo" />
          </span>
        </p>
      </div>
      <p class="c5">
        <span class="c2"></span>
      </p>
      <p class="c6">
        <span class="c2">AVISO DE PRIVACIDAD</span>
      </p>
      <p class="c5">
        <span class="c2"></span>
      </p>
      <p class="c0">
        <span class="c3"></span>
      </p>
      <p class="c1">
        <span class="c3">
          JB VEMOBILE SA DE CV (&quot;nosotros &quot;, &quot;nuestro &quot;,
          &quot;nos &quot;) se compromete a proteger y respetar tu privacidad de
          acuerdo con la Ley Federal de Protecci &oacute;n de Datos Personales
          en Posesi &oacute;n de los Particulares (&quot;la Ley &quot;) y su
          Reglamento. Este Aviso de Privacidad (&quot;Aviso &quot;) establece la
          base sobre la cual se procesar &aacute;n los datos personales que
          recopilamos de ti o que nos proporcionas. Lee detenidamente este Aviso
          para comprender nuestras pr &aacute;cticas y pol &iacute;ticas con
          respecto a tus datos personales.
        </span>
      </p>
      <p class="c0">
        <span class="c3"></span>
      </p>
      <p class="c1">
        <span class="c2">Responsable del tratamiento de datos personales</span>
      </p>
      <p class="c1">
        <span>JB VEMOBILE SA DE CV</span>
        <span class="c3">
          &nbsp;es responsable del tratamiento de tus datos personales, con
          domicilio en V &Oacute;RTICE ITECH PARK, EL MARQU &Eacute;S, QRO. C.P.
          76146, quien tratar &aacute;tus datos de conformidad con este Aviso.
        </span>
      </p>
      <p class="c0">
        <span class="c3"></span>
      </p>
      <p class="c1">
        <span class="c2">Datos personales que recopilamos</span>
      </p>
      <p class="c1">
        <span class="c3">
          Recopilamos y tratamos los siguientes datos personales:
        </span>
      </p>
      <p class="c0">
        <span class="c3"></span>
      </p>
      <p class="c1">
        <span class="c3">
          2.1. Datos de identificaci &oacute;n y contacto, como nombre completo,
          direcci &oacute;n, n &uacute;mero de tel &eacute;fono, direcci
          &oacute;n de correo electr &oacute;nico.
        </span>
      </p>
      <p class="c1">
        <span class="c3">
          2.2. Datos financieros y de facturaci &oacute;n, informaci &oacute;n
          bancaria y fiscal.
        </span>
      </p>
      <p class="c1">
        <span class="c3">
          2.3. Datos de navegaci &oacute;n y uso de nuestro sitio web, como
          direcci &oacute;n IP, cookies, registros de actividad, preferencias de
          uso, etc.
        </span>
      </p>
      <p class="c0">
        <span class="c3"></span>
      </p>
      <p class="c1">
        <span class="c2">Finalidades del tratamiento de datos personales</span>
      </p>
      <p class="c1">
        <span class="c3">
          Utilizamos tus datos personales para las siguientes finalidades:
        </span>
      </p>
      <p class="c0">
        <span class="c3"></span>
      </p>
      <p class="c1">
        <span class="c3">
          3.1. Proveerte de los productos o servicios que solicites.
        </span>
      </p>
      <p class="c1">
        <span class="c3">
          3.2. Gestionar y dar seguimiento a tus solicitudes o contratos.
        </span>
      </p>
      <p class="c1">
        <span class="c3">
          3.3. Comunicarnos contigo y responder a tus consultas, preguntas o
          solicitudes.
        </span>
      </p>
      <p class="c1">
        <span class="c3">
          3.4.Enviar comunicaciones de marketing, promociones y boletines
          informativos relacionados con nuestros productos o servicios, siempre
          y cuando hayas otorgado tu consentimiento.
        </span>
      </p>
      <p class="c1">
        <span class="c3">
          3.5. Cumplir con nuestras obligaciones legales y reglamentarias.
        </span>
      </p>
      <p class="c0">
        <span class="c3"></span>
      </p>
      <p class="c1">
        <span class="c2">Transferencias de datos personales</span>
      </p>
      <p class="c1">
        <span class="c3">
          Podemos transferir tus datos personales a terceros, nacionales o
          extranjeros, para cumplir con las finalidades descritas en este Aviso,
          siempre y cuando se cumplan los requisitos legales para dichas
          transferencias.
        </span>
      </p>
      <p class="c0">
        <span class="c3"></span>
      </p>
      <p class="c1">
        <span class="c2">Medidas de seguridad</span>
      </p>
      <p class="c1">
        <span class="c3">
          Hemos implementado medidas de seguridad t &eacute;cnicas,
          administrativas y f &iacute;sicas adecuadas para proteger tus datos
          personales contra acceso no autorizado, p &eacute;rdida, alteraci
          &oacute;n o divulgaci &oacute;n.
        </span>
      </p>
      <p class="c0">
        <span class="c3"></span>
      </p>
      <p class="c1">
        <span class="c2">
          Derechos ARCO (Acceso, Rectificaci &oacute;n, Cancelaci &oacute;n y
          Oposici &oacute;n)
        </span>
      </p>
      <p class="c1">
        <span class="c3">
          Tienes derecho a acceder, rectificar, cancelar u oponerte al
          tratamiento de tus datos personales, as &iacute;como a revocar tu
          consentimiento para su uso, en los t &eacute;rminos permitidos por la
          Ley. Para ejercer estos derechos, puedes presentar una solicitud por
          escrito a trav &eacute;s de los siguientes medios:
        </span>
      </p>
      <p class="c0">
        <span class="c3"></span>
      </p>
      <p class="c1">
        <span class="c4">atencion.clientes@timobile.com.mx</span>
      </p>
      <p class="c0">
        <span class="c3"></span>
      </p>
      <p class="c1">
        <span class="c2">Cambios en este Aviso</span>
      </p>
      <p class="c1">
        <span class="c3">
          Nos reservamos el derecho de actualizar y modificar este Aviso en
          cualquier momento, para cumplir con cambios en la legislaci &oacute;n
          o nuestras pr &aacute;cticas internas. Cualquier cambio se har
          &aacute;efectivo al publicar la versi &oacute;n revisada en nuestro
          sitio web.
        </span>
      </p>
      <p class="c0">
        <span class="c3"></span>
      </p>
      <p class="c1">
        <span class="c7">Fecha de la &uacute;ltima actualizaci &oacute;n:</span>
        <span class="c3">&nbsp;28 de junio del 2023</span>
      </p>
      <p class="c0">
        <span class="c3"></span>
      </p>
      <p class="c1">
        <span class="c3">
          Si tienes alguna pregunta, inquietud o solicitud relacionada con este
          Aviso de Privacidad, puedes comunicarte con nuestro Encargado de
          Protecci &oacute;n de Datos a trav &eacute;s de los siguientes canales
          de contacto:
        </span>
      </p>
      <p class="c0">
        <span class="c3"></span>
      </p>
      <p class="c1">
        <span class="c4">atencion.clientes@timobile.com.mx</span>
      </p>
      <p class="c0">
        <span class="c3"></span>
      </p>
      <p class="c1">
        <span class="c2">
          Gracias por leer este Aviso de Privacidad. Tu privacidad es importante
          para nosotros
        </span>
      </p>
    </div>
  );
};

export default PrivacyPolicies;
