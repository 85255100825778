export const pathRoutes = {
  home: '/',
  howWorks: '/comofunciona',
  howWorksMovil: '/comofuncionamovil',
  testimony: '/testimonios',
  contact: '/contact',
  privacyPolicies: '/privacypolicies',
  frecuentQuestions: '/FAQs'
};


export const apiWhatsApp = {
  text:'https://api.whatsapp.com/send?phone=524421113874&text=Hola%20me%20interesa%20Vemobile'
}

export const apiMessenger = {
  link:'http://m.me/368799536602913'
}