import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Form2.css";

const Form2 = () => {
    const [formData, setFormData] = useState({
    nombre: "",
    empresa: "",
    puesto: "",
    giroComercial: "",
    telefono: "",
    correo: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleFormSubmit2 = (e) => {
    e.preventDefault();
    //const formDataEncoded = new URLSearchParams(formData).toString();
  fetch("https://web.vemobile.mx/email-templates/contacto.php", {
    method: "POST",
    /*headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },*/
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
        console.log("Form data sent successfully");
      } else {
        console.error("Failed to send form data");
      }
    })
    .catch((error) => {
      console.error("Error sending form data:", error);
    });
  };
  return (
    <>
      <section className="form2-body">
        <div className="form2-container">
          <h1 className="form-title">¡Pide tu DEMO gratis!</h1>
          <h2 className="form-subtitle">
            Regístrate y te platicamos mas a detalle sobre Vemobile.
          </h2>
          <div className="form-container-all">
            <div className="form-container">
              <h3 className="form-contact">Contáctanos</h3>
              <form
                className="form"
                method="post"
                onSubmit={handleFormSubmit2}
              >
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  placeholder="Nombre completo"
                  required
                  value={formData.nombre}
                  onChange={handleInputChange}
                />

                <input
                  type="text"
                  id="empresa"
                  name="empresa"
                  placeholder="Empresa"
                  required
                  value={formData.empresa}
                  onChange={handleInputChange}
                />

                <input
                  type="text"
                  id="puesto"
                  name="puesto"
                  placeholder="Puesto"
                  required
                  value={formData.puesto}
                  onChange={handleInputChange}
                />

                <select
                  id="giro-comercial"
                  name="giro-comercial"
                  required
                  onChange={handleInputChange}
                >
                  <option id="index-0" value="">
                    Giro Comercial
                  </option>
                  <option value="">Selecciona tu industria*</option>
                  <option value="Abarrotes">Abarrotes</option>
                  <option value="Bebidas / Refrescos">
                    Bebidas / Refrescos
                  </option>
                  <option value="Botanas">Botanas</option>
                  <option value="Café">Café</option>
                  <option value="Carniceria">Carniceria</option>
                  <option value="Dulces">Dulces</option>
                  <option value="Farmacéutico">Farmacéutico</option>
                  <option value="Ferretería">Ferretería</option>
                  <option value="Galletas">Galletas</option>
                  <option value="Helados">Helados</option>
                  <option value="Hielo">Hielo</option>
                  <option value="Huevo">Huevo</option>
                  <option value="Lácteos">Lácteos</option>
                  <option value="Lubricantes Automotrices">
                    Lubricantes Automotrices
                  </option>
                  <option value="Miel">Miel</option>
                  <option value="Panificadora">Panificadora</option>
                  <option value="Pastelería / Materias">
                    Pastelería / Materias
                  </option>
                  <option value="Productos de Belleza / Cosméticos">
                    Productos de Belleza / Cosméticos
                  </option>
                  <option value="Productos de limpieza">
                    Productos de limpieza
                  </option>
                  <option value="Productos Hospitalarios">
                    Productos Hospitalarios
                  </option>
                  <option value="Productos para Mascotas / Veterinarias">
                    Productos para Mascotas / Veterinarias
                  </option>
                  <option value="Purificadoras de agua">
                    Purificadoras de agua
                  </option>
                  <option value="Salsas y Aderezos">Salsas y Aderezos</option>
                  <option value="Tortillas / Tostadas">
                    Tortillas / Tostadas
                  </option>
                  <option value="Otro">Otro</option>
                </select>

                <input
                  type="tel"
                  id="telefono"
                  name="telefono"
                  placeholder="Teléfono"
                  required
                  value={formData.telefono}
                  onChange={handleInputChange}
                />

                <input
                  type="email"
                  id="correo"
                  name="correo"
                  placeholder="Correo"
                  required
                  value={formData.correo}
                  onChange={handleInputChange}
                />

                <button type="submit">Enviar</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

Form2.propTypes = {
  handleSubmit2: PropTypes.func.isRequired,
};

export default Form2;
