import React from "react";
import "./Section3-HowWorksMovil.css";
import productosMovil from "../../../assets/img/productosMovil.webp";

export const Section3HowWorksMovil = () => {
  return (
    <section className="section3-HowWorksMovil-container" id="Productos">
      <section className="section3-HowWorksMovil-textimg">
        <section className="section3-HowWorksMovil">
          <h1 className="section3-HowWorksMovil-title">Productos</h1>
            <div className="section3-HowWorksMovil-text">
              <ul className="section3-HowWorkMovil-ul">
                <li className="section3-HowWorkMovil-li">
                Te mostramos el detalle de tus productos, existencias, precios de lista y descuentos, entre otros.
                </li>
              </ul>
            </div>
        </section>
        <div className="section3-HowWorksMovil-img">
          <img
            className="section3-HowWorksMovil-img-productos"
            src={productosMovil}
            alt="productos"
            loading="lazy"
          />
        </div>
      </section>
    </section>
  );
};
