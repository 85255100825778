import React from "react";
import "./Section2-HowWorks.css";
import configuracion from "../../../assets/img/configuracion.webp";

export const Section2HowWorks = () => {
  return (
    <section className="section2-HowWorks-container" id="Configuración">
      <section className="section2-HowWorks-textimg">
        <section className="section2-HowWorks">
          <h1 className="section2-HowWorks-title">Configuración</h1>
            <div className="section2-HowWorks-text">
              <ul className="section2-HowWork-ul">
                <li className="section2-HowWork-li">
                  Administra y gestiona todos tus usuarios,
                  roles, zonas, tickets y ajustes generales
                </li>
              </ul>
            </div>
        </section>
        <div className="section2-HowWorks-img">
          <img
            className="section2-HowWorks-img-configuracion"
            src={configuracion}
            alt="configuracion"
            loading="lazy"
          />
        </div>
      </section>
    </section>
  );
};
