import './Section4-HowWorksMovil.css'
import reportes from '../../../assets/img/reportes.webp'

import React from 'react'

export const Section4HowWorksMovil = () => {
  return (
    <div>    <section className="section4-HowWorksMovil-container" id="Reportes"> 
    <section className="section4-HowWorksMovil-textimg">
      <section className="section4-HowWorksMovil">
        <h1 className="section4-HowWorksMovil-title">Reportes</h1>
          <div className="section4-HowWorksMovil-text">
            <ul className="section4-HowWorkMovil-ul">
              <li className="section4-HowWorkMovil-li">
              Revisa en todo momento el avance de tus ventas diarias, Clientes visitados, productos vendidos, tipo de operación, fechas, folios y cantidades totales.
              </li>
            </ul>
          </div>
      </section>
      <div className="section4-HowWorksMovil-img">
        <img className="section4-HowWorksMovil-img-reportes" src={reportes} alt="reportes" loading="lazy"/>
      </div>
    </section>
  </section></div>
  )
}
