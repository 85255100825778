import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/Globals/Header/Header";
import BannerYoutube from "../components/LearnMore/BannerYoutube";
import {Section1HowWorksMovil} from "../components/HowWorksMovil/Section1/Section1-HowWorksMovil"
import {Section2HowWorksMovil} from "../components/HowWorksMovil/Section2/Section2-HowWorksMovil"
import {Section3HowWorksMovil} from "../components/HowWorksMovil/Section3/Section3-HowWorksMovil"
import {Section4HowWorksMovil} from "../components/HowWorksMovil/Section4/Section4-HowWorksMovil"
import {Section5HowWorksMovil} from "../components/HowWorksMovil/Section5/Section5-HowWorksMovil"
import {Section6HowWorksMovil} from "../components/HowWorksMovil/Section6/Section6-HowWorksMovil"
import {Section7HowWorksMovil} from "../components/HowWorksMovil/Section7/Section7-HowWorksMovil"
import {Section8HowWorksMovil} from "../components/HowWorksMovil/Section8/Section8-HowWorksMovil"
import Messenger from "../components/Globals/Messenger/Messenger";

import WhatsApp from "../components/Globals/WhatsApp/WhatsApp";

function LearnMore(){
    const location = useLocation();
    const selectedPage = location.pathname; 
    const onPageSelectHandler = (page) => {
        // Lógica para manejar el cambio de página aquí
        console.log("Se seleccionó la página:", page);
    };

   
    return(
        <div>
            <Header onPageSelect={onPageSelectHandler} selectedPage={selectedPage} />
            <Section1HowWorksMovil/>
            <Section2HowWorksMovil/>
            <Section3HowWorksMovil/>
            <Section4HowWorksMovil/>
            <Section5HowWorksMovil/>
            <Section6HowWorksMovil/>
            <Section7HowWorksMovil/>
            <Section8HowWorksMovil/>
            <WhatsApp />
            <Messenger />
            <BannerYoutube/>
        </div>
    );
}
export default LearnMore;