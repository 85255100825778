import React from "react";
import "./Section3.css";
import Logo from "../../../assets/img/imagen_seccion3.webp";
import Linea from "../../../assets/img/linea_naranja.png";

function Section3() {
  // YouTube video options
  const videoOpts = {
    height: "360",
    width: "640",
    playerVars: {
      // You can add YouTube player options here, e.g., autoplay, controls, etc.
    },
  };

  return (
    <section className="section3">
      <article className="section3-info">
        <ul>
          <li>CONOCE LO QUE</li>
          <li>VEMOBILE HACE</li>
          <li>POR TU EMPRESA.</li>
          <img id="section3-underlined" src={Linea} alt="linea" />
          
        </ul>
      </article>
      <section className="section3-video">
      <iframe  src="https://www.youtube.com/embed/IGKtePaI4NA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </section>
    </section>
  );
}

export default Section3;
